<mat-nav-list>
  <a mat-list-item routerLink="/" (click)="onSidenavClose()">
       <span class="nav-caption" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</span>
  </a>
  <a mat-list-item routerLink="/about-us" (click)="onSidenavClose()" routerLinkActive="active">
       <span class="nav-caption">About Us</span>
  </a>
  <a mat-list-item routerLink="/contact-us" (click)="onSidenavClose()" routerLinkActive="active">
      <span class="nav-caption">Contact Us</span>
  </a>



</mat-nav-list>
