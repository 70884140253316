<div class="header-inner"></div>
<div class="container">
<ng-container *ngIf="(cart$ | async) as cart">
  <div *ngIf="!cart || !cart.length">Your cart is empty</div>
  <ng-container *ngIf="cart && cart.length">
    <div mat-dialog-content>
<div class="cart-wrapp-mob">  
  <h5 mat-dialog-title>
    My Cart ( {{cart.length}} )
  </h5>
      <mat-card *ngFor="let item of cart" class="grid-my-list" >
        <div>
          <div *ngIf="item.product?.shop_product_selected_variant?.type.id"
          [matTooltip]="(item.product?.shop_product_selected_variant?.type.id == 1) ? 'Veg' : ( (item.product?.shop_product_selected_variant?.type.id == 2) ? 'Non-Veg':'' )"
         [ngClass]="{'veg': item.product?.shop_product_selected_variant?.type.id == 1, 'nonveg': item.product?.shop_product_selected_variant?.type.id == 2}"></div>
         <a href="javascript:{}"  [routerLink]="'/'+item.product.shop_product_category?.url+'/'+item.product.url">
          <img [src]="(item.product?.shop_product_selected_variant?.shop_product_image?.image) ? item.product?.shop_product_selected_variant.shop_product_image.image_path: 'assets/no-image.jpg'">
         </a>
        </div>
        <div >
       <h4 class="cart-item-header pl-1" > {{item.product.name}}</h4>
       
        <b class="block pl-1">₹ {{item.product.shop_product_selected_variant.price}} X {{ item.qty}} = {{ item.price }}</b>
        <small class="block pl-1">{{ item.qty}} {{ (item.qty > 1) ? 'packets': 'packet' }} of {{item.product.shop_product_selected_variant.name}}</small>
       <small class="block pl-1" *ngIf="item.message"> {{item.message}}</small>
       <div class="d-none-lg">
       <button mat-icon-button color="primary" (click)="updateCart(item, '-')">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      {{item.qty}}
      <button mat-icon-button color="primary" (click)="updateCart(item, '+')">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>
      </div>
      <div class="d-none-md">
        <button mat-icon-button color="primary"  (click)="updateCart(item, '-')"
        [matTooltip]="'Decrease ' +item.product.name+ ' quantity'">
          <mat-icon>remove_circle_outline</mat-icon>
        </button>
        {{item.qty}}
        <button mat-icon-button color="primary" (click)="updateCart(item, '+')"
        [matTooltip]="'Increase ' +item.product.name+ ' quantity'">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>

<div class="comment-area">

   <!-- <ng-container *ngIf="item.message"> -->
    <div >
      <button mat-icon-button color="primary" (click)="editMessage(item)"
      [matTooltip]="'Add a custom message for '+item.product.name">
     <span class="mob-comments">Add wishes note</span>  <mat-icon>add_comment</mat-icon>
      </button>
    <!-- </ng-container> -->
        <button mat-icon-button color="warn" (click)="deleteItem(item)"
        [matTooltip]="'Remove ' +item.product.name+ ' from cart'">
        <span class="mob-comments">Remove Item</span>  <mat-icon>delete</mat-icon>
        </button>
      </div>
      </div>
      </mat-card>
    </div>
      <div class="m-15">
        <form [formGroup]="customerFrm">
          <div class="selectDelevery" >
         <h3>Select your delivery location.</h3>
          </div>

          <div class="tab-delivery-options">
            <ng-container *ngIf="(shop$ | async) as shop">
            <mat-tab-group mat-align-tabs="start">
              <mat-tab label="Pick up from shop">
                <ng-container *ngFor="let shop_delivery of shop?.shop_delivery_filtered.shops">
                  <a matRipple href="javascript:{}"
                    class="animate__animated"
                    [matTooltip]="'Select '+shop_delivery.name+' as your delivery location' "
                    [ngClass]="{'active animate__headShake' : (selectedLocation?.id == shop_delivery.id)}"
                    (click)="changeLocation(shop_delivery)">
                    <div class="d-flex-btn">
                      {{ shop_delivery.name }}
                      <span *ngIf="shop_delivery.charge">
                        ( + ₹ {{ shop_delivery.charge}} )
                      </span>
                      <mat-icon class="mr-auto">keyboard_arrow_right</mat-icon>
                    </div>
                    </a>
                </ng-container>
              </mat-tab>
              <mat-tab label="Home Delivery">
                <ng-container *ngFor="let shop_delivery of shop?.shop_delivery_filtered.home">
                  <b class="d-block"><mat-icon class="warning-ico">error</mat-icon>Additional <span class="btn-p-m-even" *ngIf="shop_delivery.charge">₹ {{ shop_delivery.charge}}</span>  for home delivery</b>
                  <a matRipple href="javascript:{}"
                    class="animate__animated primry-vsible"
                    [matTooltip]="'Select '+shop_delivery.name+' as your delivery location' "
                    [ngClass]="{'active animate__headShake' : (selectedLocation?.id == shop_delivery.id)}"
                    (click)="changeLocation(shop_delivery)">
                    <div class="d-flex-btn">
                     <span class="d-none"> {{ shop_delivery.name }} </span>
                      ADD YOUR DELIVERY ADDRESS
                      <!--span class="btn-pl-3" *ngIf="shop_delivery.charge">( + ₹ {{ shop_delivery.charge}} )</span-->
                      <mat-icon class="mr-auto">keyboard_arrow_right</mat-icon>
                    </div>
                    </a>
                    <div style="display: none;" [ngClass]="{'active animate__headShake' : (selectedLocation?.id == shop_delivery.id)}" class="add-home-delivery-btn animate__animated"><div class="d-flex-btn">ADD YOUR DELIVERY ADDRESS <mat-icon class="mr-auto">keyboard_arrow_right</mat-icon></div></div>
                </ng-container>
              </mat-tab>
            </mat-tab-group>
          </ng-container>
        </div>

        </form>

      </div>

      <div class="order-total m-15">
        <table>
          <tr>
            <th>Detais</th>
            <th width="150px" class="text-right">Total</th>
          </tr>
          <tr  *ngFor="let item of cart">
            <td>
              {{item.product.name}}  <small class="pl-3">{{item.product.shop_product_selected_variant.name}}</small>
            </td>
            <td class="text-right">
              <b class="block pl-1">₹ {{item.product.shop_product_selected_variant.price}} X {{ item.qty}} = {{ item.price }}</b>
            </td>
          </tr>
          <tr  *ngIf="selectedLocation && selectedLocation?.charge">
            <td><b>Home delivery Cost:</b> </td>
            <td class="text-right"><b> ₹ {{ selectedLocation?.charge }}</b></td>
          </tr>
          <tr>
            <td><small class="block"> Total Items:  <b>{{cart.length}}</b> </small></td>
            <td class="text-right"><h1>₹  {{ grandTotal }}</h1>
              <small>Inc. of all taxes</small></td>
          </tr>
        </table>
        <div>
            <small class="block mt-10" *ngIf="selectedLocation && selectedLocation?.id">
              {{selectedLocation.description }}
            </small>
          </div>
          
      
      </div>
    </div>
    <section class="address" *ngIf="selectedLocation?.id">
      <button mat-icon-button color="primary" class="right-position" (click)="triggerOrderForm()">
        <mat-icon>edit</mat-icon>
      </button>

      <h3 *ngIf="f.name.valid">{{f.name.value}}</h3>
      <h4 *ngIf="f.phone.valid"> Ph: {{ f.phone.value }}
        <mat-error *ngIf="f.phone.invalid">In valid phone number</mat-error>
      </h4>
      <p *ngIf="f.delivery_date.value">Delivery on: {{ f.delivery_date.value | date: 'dd/MM/yyyy'}}</p>
      <p *ngIf="f.hour.value && f.minute.value && f.ampm.value">Time: {{f.hour.value}}.{{f.minute.value}} {{f.ampm.value}}</p>
      <p *ngIf="selectedLocation.id">Location: {{selectedLocation.name}};</p>
      <ng-container *ngIf="selectedLocation.need_cust_loc">
        <p *ngIf="f.address.value">Address: {{f.address.value}}</p>
        <p *ngIf="f.pin.value">Pin: {{f.pin.value}}</p>
      </ng-container>
      <p *ngIf="f.note.value">{{ f.note.value }}</p>
    </section>

    <div mat-dialog-actions class="actionBtn footer-floatingbutton" *ngIf="isShowButton()">
      <section class="example-section" [matTooltip]="(!terms) ? 'Please accept terms' : ''">

        <mat-checkbox [(ngModel)]="terms"></mat-checkbox><a href="javascript:{}" class="ml-5" (click)="triggerTerms()">I AGREE </a>
        <p class="pl-pr">Click and continue this order button and place your order to get the final summary and send it to us via WhatsApp for order.</p>
        
        <button class="whatsapp-btn" mat-raised-button color="primary"  type="submit" [disabled]="!terms" (click)="sendToShop()">
          <!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 682 682" xml:space="preserve">
          <path class="st0" d="M567.2,113.9C507.3,54,427.8,21,343,21C168.4,21,26.3,163.1,26.2,337.8c0,55.8,14.6,110.3,42.3,158.4
            L23.6,660.4l168-44.1c46.3,25.2,98.4,38.5,151.4,38.6h0.1c174.6,0,316.8-142.1,316.8-316.8C659.9,253.4,627,173.8,567.2,113.9z
             M343,601.4h-0.1c-47.3,0-93.6-12.7-134-36.7l-9.6-5.7l-99.7,26.1l26.6-97.2l-6.3-10C93.6,436,79.7,387.6,79.7,337.8
            C79.8,192.6,197.9,74.5,343.2,74.5c70.3,0,136.5,27.4,186.2,77.2s77.1,115.9,77.1,186.3C606.3,483.2,488.2,601.4,343,601.4z
             M487.5,404.1c-7.9-4-46.8-23.1-54.1-25.8c-7.3-2.6-12.5-4-17.8,4c-5.3,7.9-20.4,25.8-25.1,31c-4.6,5.3-9.2,5.9-17.2,2
            c-7.9-4-33.4-12.3-63.7-39.3c-23.5-21-39.4-46.9-44-54.8c-4.6-7.9,0-11.8,3.5-16.2c8.6-10.6,17.2-21.8,19.8-27.1
            c2.6-5.3,1.3-9.9-0.7-13.9c-2-4-17.8-42.9-24.4-58.8c-6.4-15.4-13-13.3-17.8-13.6c-4.6-0.2-9.9-0.3-15.2-0.3c-5.3,0-13.9,2-21.1,9.9
            c-7.3,7.9-27.7,27.1-27.7,66s28.4,76.6,32.3,81.9c4,5.3,55.8,85.2,135.2,119.5c18.9,8.2,33.6,13,45.1,16.7c19,6,36.2,5.2,49.9,3.1
            c15.2-2.3,46.8-19.2,53.4-37.6c6.6-18.5,6.6-34.3,4.6-37.6C500.7,410.1,495.4,408.1,487.5,404.1z"/>
          </svg>
          ORDER ON WHATSAPP <mat-icon class="mr-auto">keyboard_arrow_right</mat-icon> </button>

      </section>


    </div>
  </ng-container>
</ng-container>
</div>
