<mat-carousel
  timings="250ms ease-in"
  [autoplay]="true"
  interval="5000"
  color="accent"
  maxWidth="auto"
  proportion="25"
  slides="5"
  [loop]="true"
  [hideArrows]="true"
  [hideIndicators]="true"
  [useKeyboard]="true"
  [useMouseWheel]="false"
  orientation="ltr"
>
  <mat-carousel-slide
  #matCarouselSlide
  *ngFor="let slide of (slides$ | async); let i = index"
  [image]="slide.image"
  overlayColor="#00000040"
  [hideOverlay]="true"
  >
    <div class="container banner-padding">
      <div class="banner-text">
        <h1>Online</h1>
        <h1>Cake</h1>
        <h4>Kuruppampady & Pattal</h4>
      </div>
    </div>

  </mat-carousel-slide>
</mat-carousel>
<div class="serch-web">
  <div class="container">
  <app-search></app-search>
</div>

</div>
<div class="container">
  <!-- <div class="swipe" (swipe)="onSwipe($event)">
    <h1>Swipe Gesture</h1>
    <p>Works with both mouse and touch.</p>
    <h5 [innerHTML]="direction"></h5>
  </div> -->

<app-search class="search-mob"></app-search>
<app-categories></app-categories>
<router-outlet></router-outlet>

</div>
