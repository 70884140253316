<mat-nav-list>
  <a mat-list-item routerLink="/admin" (click)="onSidenavClose()">
      <mat-icon>home</mat-icon> <span class="nav-caption" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</span>
  </a>


  <ng-container *ngIf="(loggedUser$ | async) as loggedUser">
    <a mat-list-item routerLink="/admin/categories" (click)="onSidenavClose()" routerLinkActive="active">
      <mat-icon>shopping_basket</mat-icon><span class="nav-caption">Categories</span>
    </a>
    <a mat-list-item routerLink="/admin/products/0" (click)="onSidenavClose()" routerLinkActive="active">
      <mat-icon>cake</mat-icon><span class="nav-caption">Products</span>
    </a>
    <a mat-list-item routerLink="/admin/deliveries" (click)="onSidenavClose()" routerLinkActive="active">
      <mat-icon>room</mat-icon><span class="nav-caption">Delivery Locations</span>
    </a>
    <a mat-list-item routerLink="/admin/details" (click)="onSidenavClose()" routerLinkActive="active">
      <mat-icon>store</mat-icon><span class="nav-caption">Shop Info</span>
    </a>
    <a mat-list-item routerLink="/admin/orders" (click)="onSidenavClose()" routerLinkActive="active">
      <mat-icon>shopping_cart</mat-icon><span class="nav-caption">Orders</span>
    </a>
    <a mat-list-item routerLink="/admin/edit-profile" (click)="onSidenavClose()" routerLinkActive="active">
      <mat-icon>person</mat-icon><span class="nav-caption">Edit Profile</span>
    </a>
    <a mat-list-item routerLink="/admin/edit-profile" (click)="onSidenavClose()" (click)="signOut()">
      <mat-icon>power_settings_new</mat-icon><span class="nav-caption">Sign Out</span>
    </a>
  </ng-container>

</mat-nav-list>
