
  <app-cart *ngIf="!(isAdmin$ | async)"></app-cart>
<footer>
  <div class="container">
<div class="footergrid webfooter">
  <div><mat-icon class="font-30"> call</mat-icon></div>
  <div>
    <h4 class="text-left"> +91 994 621 1178</h4>
    <h4 class="text-left"> +91 904 891 1178</h4>
  </div>
  <div>
    <mat-icon class="font-30"> pin_drop</mat-icon>
  </div>
  <div>
    <h3 class="text-left" >Bread N Butter</h3>
    <h4 class="text-left" >A M Road Kuruppampady Kuruppampady, Kerala, India 683545</h4>
  </div>
  <div>
    <h3 class="text-left" >Bread N Butter</h3>
    <h4 class="text-left" >A M Road Pattal Iringole, Kerala, India 683545</h4>
  </div>
   <div><mat-icon class="font-30">mail</mat-icon></div>
  <div><h4 class="text-left"><a href="mailto:sales@breadnbutter.co.in?subject=Bread n Butter Enquiry">sales@breadnbutter.co.in</a></h4></div>
</div>

<div class="footergrid mob-footer">
  <div class="d-flex-footer">
    <div><mat-icon class="font-30"> call</mat-icon></div>
    <div>
      <h4 class="text-left"> <a href="tel://+919946211178">+91 994 621 1178</a></h4>
      <h4 class="text-left"> <a href="tel://+919048911178">+91 904 891 1178</a></h4>
    </div>
  </div>
  <div class="d-flex-footer">
    <div>
      <mat-icon class="font-30"> pin_drop</mat-icon>
    </div>
    <div>
      <h3 class="text-left" >Bread N Butter</h3>
      <h4 class="text-left" >A M Road Kuruppampady Kuruppampady, Kerala, India 683545</h4>
      <h3 class="text-left" >Bread N Butter</h3>
      <h4 class="text-left" >A M Road Pattal Iringole, Kerala, India 683545</h4>
    </div>
  </div>
  <div class="d-flex-footer">
    <div><mat-icon class="font-30">mail</mat-icon></div>
    <div><h4 class="text-left"><a href="mailto:sales@breadnbutter.co.in?subject=Bread n Butter Enquiry">sales@breadnbutter.co.in</a></h4></div>
  </div>
</div>

<p>Copyright © {{currentYear}} {{environment.siteName}}. All Rights Reserved</p>
</div>
</footer>
