<div class="inner-banner">
  <div class="container banner-padding">
    <div class="banner-text">
    <h1>Contact Us</h1>
    <h4>Bread N Butter</h4>
    </div>
    </div>
</div>
<div class="container">

  <div class="contactus-grid">
    <div><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1963.9281605684014!2d76.51760312096349!3d10.110844675795683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b07e3a725fbd715%3A0xe531f4b6d164d40f!2sBread%20N%20Butter!5e0!3m2!1sen!2sin!4v1622139108837!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe></div>
    <div>

        <div class="d-flex-footer">
          <div><mat-icon class="font-30"> call</mat-icon></div>
          <div>
            <h4 class="text-left"> +91 994 621 1178</h4>
            <h4 class="text-left"> +91 904 891 1178</h4>
          </div>
        </div>
        <div class="d-flex-footer">
          <div>
            <mat-icon class="font-30"> pin_drop</mat-icon>
          </div>
          <div>
            <h3 class="text-left" >Bread N Butter</h3>
            <h4 class="text-left" >A M Road Kuruppampady Kuruppampady, Kerala, India 683545</h4>
            <h3 class="text-left" >Bread N Butter</h3>
            <h4 class="text-left" >A M Road Pattal Iringole, Kerala, India 683545</h4>
          </div>
        </div>
        <div class="d-flex-footer">
          <div><mat-icon class="font-30">mail</mat-icon></div>
          <div><h4 class="text-left">
            <a href="mailto:sales@breadnbutter.co.in?subject=Bread n Butter Enquiry">sales@breadnbutter.co.in</a>
          </h4></div>
        </div>

    </div>
  </div>
</div>
