<form class="vw-80" [formGroup]="addToCartFrm" (ngSubmit)="addToCart()">
  <h4 mat-dialog-title class="mb-2">
    Add To Cart</h4>
    <h2>{{data.name}}</h2>
    <mat-card-subtitle>{{ data.shop_product_selected_variant?.name}}</mat-card-subtitle>

  <div mat-dialog-content>
<div class="product-image">
  <div *ngIf="data.shop_product_selected_variant?.type.id"
  [matTooltip]="(data?.shop_product_selected_variant?.type.id == 1) ? 'Veg' : ( (data?.shop_product_selected_variant?.type.id == 2) ? 'Non-Veg':'' )"
  [ngClass]="{'veg': data.shop_product_selected_variant?.type.id == 1, 'nonveg': data.shop_product_selected_variant?.type.id == 2}"></div>
    <img loading="lazy"
    [src]="(data?.shop_product_selected_variant?.shop_product_image?.image) ?  data.shop_product_selected_variant.shop_product_image.image_path : 'assets/no-image.jpg'" [alt]="data.name+' '+data.shop_product_selected_variant?.name">
  </div>
    <mat-form-field appearance="standard" *ngIf="data.shop_product_variant.length > 1">
      <mat-label>Choose Variant</mat-label>
      <mat-select class="font-20" formControlName="shop_product_variant_id" required (selectionChange)="chooseVarient()">
        <mat-option *ngFor="let shop_product_variant of data.shop_product_variant" [value]="shop_product_variant.id">
          {{shop_product_variant.name}} - ₹ {{ shop_product_variant.price }}

        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.shop_product_variant_id.errors && f.shop_product_variant_id.errors.error">
        {{f.shop_product_variant_id.errors.error}}
      </mat-error>
    </mat-form-field>


<div class="relativePosition d-none">
    <button type="button" class="animate__animated" mat-icon-button color="primary" (click)="updateCart('-')" [disabled]="qty<2"
    matTooltip="Decrease the quantity" [ngClass]="{'animate__flipInY' : qty<2 }">
      <mat-icon>remove_circle_outline</mat-icon>
    </button>
    {{qty}}
    <button type="button" mat-icon-button color="primary" (click)="updateCart('+')"
    matTooltip="Increase the quantity">
      <mat-icon>add_circle_outline</mat-icon>
    </button>
  </div>
    <ng-container *ngIf="this.data.shop_product_selected_variant?.price">
      <div class="d-flex mt-3 mb-3 justify-content-center">
      <!-- <del *ngIf="this.data.shop_product_selected_variant?.actual_price">₹ {{ this.data.shop_product_selected_variant?.actual_price }}</del> -->
       <span class="weight-bold">₹ {{ this.data.shop_product_selected_variant?.price }}</span>
      <!-- {{ this.data.shop_product_selected_variant?.description }} -->
      <!-- <span *ngIf="this.data.shop_product_selected_variant?.type.id">
        {{ this.data.shop_product_selected_variant?.type.name }}
      </span> -->
    </div>
    </ng-container>
    <!-- <mat-form-field class="full-width-text" appearance="standard">
      <mat-label>Message</mat-label>
      <textarea matInput formControlName="message" placeholder="Ex. Happy Birth Day John Doe"></textarea>
      <mat-error *ngIf="f.message.errors && f.message.errors.error">
        {{f.message.errors.error}}
      </mat-error>
    </mat-form-field> -->

  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button type="button" (click)="dialogRef.close()" class="mob-btn-lg-size">Close</button>
    <button class="mat-primary_yellow mob-btn-lg-size" mat-raised-button color="primary" type="submit"><mat-icon>add_shopping_cart</mat-icon> ADD</button>
  </div>
</form>
