<ng-container class="example-button-container" *ngIf="(cart$ | async) as cart">
  <ng-container *ngIf="cart && cart.length && !(hideCartComponent$ | async) ">
    <a   mat-fab color="primary" class="extended-fab-button animate__animated animate__slideInUp animate__faster" routerLink="/bag" aria-label="Example icon button with a delete icon">
      <div class="extend-btn-cart" [ngClass]="!isChanged ? 'animate__animated animate__headShake animate__faster' : ''">
        <div class="left-align">
          On Your Bag
          <span class="d-block">{{cart.length}} <span class="items">{{ (cart.length > 1) ? 'Items' : 'Item' }}</span> </span>

        </div>
        <div><mat-icon>shopping_cart</mat-icon></div>

        <div class="right-align">Total Price
        <span class="d-block"> <span class="items">₹</span> {{ total}}</span></div>

      </div>


    </a>
  </ng-container>
</ng-container>

<div >



</div>
